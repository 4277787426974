// 账簿设置
export const TEMPLATE_ACCOUNTING_BOOK_VIEW = {
    name: 'templateAccountingBookView',
    path: 'template/accountingBook',
    meta: { name: '账簿设置查询', keepAlive: true, uid: 'templateAccountingBook' },
    component: () =>
        import ('@/views/template/accountingBook')
}

export const TEMPLATE_ACCOUNTING_BOOK_DETAIL_VIEW = {
    name: 'templateAccountingBookDetailView',
    path: 'template/accountingBook/detail/:id',
    meta: { name: '账簿设置', keepAlive: true, uid: 'templateAccountingBookDetailView' },
    component: () =>
        import ('@/views/template/accountingBook/detail')
}

// 科目分类
export const TEMPLATE_CATEGORY_VIEW = {
    name: 'templateCategoryView',
    path: 'templateCategory',
    meta: { name: '科目分类', keepAlive: true, uid: 'templateCategoryView' },
    component: () =>
        import ('@/views/template/category')
}

export const TEMPLATE_CATEGORY_DETAIL_VIEW = {
    name: 'templateCategoryDetailView',
    path: 'templateCategory/detail/:id',
    meta: { name: '科目分类查看', keepAlive: true, uid: 'templateCategoryDetailView' },
    component: () =>
        import ('@/views/template/category/detail')
}

export const TEMPLATE_CATEGORY_EDIT_VIEW = {
    name: 'templateCategoryEditView',
    path: 'templateCategory/edit/:id',
    meta: { name: '科目分类编辑', keepAlive: true, uid: 'templateCategoryEditView' },
    component: () =>
        import ('@/views/template/category/edit')
}

// 模板
export const TEMPLATE_VIEW = {
    name: 'templateView',
    path: 'template',
    meta: { name: '凭证模板', keepAlive: true, uid: 'templateView' },
    component: () =>
        import ('@/views/template/template')
}
export const TEMPLATE_DETAIL_VIEW = {
    name: 'templateDetailView',
    path: 'template/template/detail/:id',
    meta: { name: '凭证模板查看', keepAlive: true, uid: 'templateDetailView' },
    component: () =>
        import ('@/views/template/template/detail')
}

// 权限
// 权限 - 账号管理
export const PERMISSION_USER_VIEW_ROUTE = {
    name: 'permissionUserView',
    path: 'permission/user',
    meta: { name: '账号管理', keepAlive: true, uid: 'permissionUserView' },
    component: () =>
        import ('@/views/settings/permission/user')
}

export const PERMISSION_USER_DETAIL_VIEW_ROUTE = {
    name: 'permissionUserDetailView',
    path: 'permission/user/detail/:id',
    meta: { name: '账号详情', keepAlive: true, uid: 'permissionUserDetailView' },
    component: () =>
        import ('@/views/settings/permission/user/detail')
}

export const PERMISSION_USER_EDIT_VIEW_ROUTE = {
    name: 'permissionUserEditView',
    path: 'permission/user/edit/:id',
    meta: { name: '账号编辑', keepAlive: true, uid: 'permissionUserEditView' },
    component: () =>
        import ('@/views/settings/permission/user/edit')
}

export const PERMISSION_USER_ADD_VIEW_ROUTE = {
    name: 'permissionUserAddView',
    path: 'permission/user/add',
    meta: { name: '账号新增', keepAlive: true, uid: 'permissionUserAddView' },
    component: () =>
        import ('@/views/settings/permission/user/add')
}

// 角色
export const PERMISSION_ROLE_VIEW_ROUTE = {
    name: 'permissionRoleView',
    path: 'permission/role',
    meta: { name: '角色管理', keepAlive: true, uid: 'permissionRoleView' },
    component: () =>
        import ('@/views/settings/permission/role')
}
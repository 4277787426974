// 结算方式
export const SETTLEMENT_METHOD_VIEW_ROUTE = {
    name: 'settlementMethodView',
    path: 'cashier/settlementMethod',
    meta: { name: '结算方式管理', keepAlive: true, uid: 'settlementMethodView' },
    component: () =>
        import ('@/views/cashier/settlementMethod')
}
export const SETTLEMENT_METHOD_DETAIL_VIEW_ROUTE = {
    name: 'settlementMethodDetailView',
    path: 'cashier/settlementMethod/detail/:id',
    meta: { name: '结算方式管理查看', keepAlive: true, uid: 'settlementMethodDetailView' },
    component: () =>
        import ('@/views/cashier/settlementMethod/detail')
}

export const SETTLEMENT_METHOD_ADD_VIEW_ROUTE = {
    name: 'settlementMethodAddView',
    path: 'cashier/settlementMethod/add',
    meta: { name: '结算方式管理新增', keepAlive: true, uid: 'settlementMethodAddView' },
    component: () =>
        import ('@/views/cashier/settlementMethod/add')
}

export const SETTLEMENT_METHOD_EDIT_VIEW_ROUTE = {
    name: 'settlementMethodEditView',
    path: 'cashier/settlementMethod/edit/:id',
    meta: { name: '结算方式管理编辑', keepAlive: true, uid: 'settlementMethodEditView' },
    component: () =>
        import ('@/views/cashier/settlementMethod/edit')
}

// 账户
export const ACCOUNT_VIEW_ROUTE = {
    name: 'accountView',
    path: 'cashier/account',
    meta: { name: '账户', keepAlive: true, uid: 'accountView' },
    component: () =>
        import ('@/views/cashier/account')
}
export const ACCOUNT_DETAIL_VIEW_ROUTE = {
    name: 'accountDetailView',
    path: 'cashier/account/detail/:id',
    meta: { name: '账户查看', keepAlive: true, uid: 'accountDetailView' },
    component: () =>
        import ('@/views/cashier/account/detail')
}

export const ACCOUNT_ADD_VIEW_ROUTE = {
    name: 'accountAddView',
    path: 'cashier/account/add',
    meta: { name: '账户新增', keepAlive: true, uid: 'accountAddView' },
    component: () =>
        import ('@/views/cashier/account/add')
}

export const ACCOUNT_EDIT_VIEW_ROUTE = {
    name: 'accountEditView',
    path: 'cashier/account/edit/:id',
    meta: { name: '账户编辑', keepAlive: true, uid: 'accountEditView' },
    component: () =>
        import ('@/views/cashier/account/edit')
}

// 账户初始化
export const ACCOUNT_INIT_VIEW_ROUTE = {
    name: 'accountInitView',
    path: 'cashier/account/init',
    meta: { name: '账户初始化', keepAlive: true, uid: 'accountInitView' },
    component: () =>
        import ('@/views/cashier/account/init')
}

// 账户日记账
export const PAY_ITEM_CASH_VIEW_ROUTE = {
    name: 'payItemCashView',
    path: 'cashier/pay/item/cash',
    meta: { name: '现金日记账', keepAlive: true, uid: 'payItemCashView' },
    component: () =>
        import ('@/views/cashier/pay/item/cash')
}

export const PAY_ITEM_BANK_VIEW_ROUTE = {
    name: 'payItemBankView',
    path: 'cashier/pay/item/bank',
    meta: { name: '银行日记账', keepAlive: true, uid: 'payItemBankView' },
    component: () =>
        import ('@/views/cashier/pay/item/bank')
}

// 应收执行单
export const REPAY_CONFIRM_VIEW_ROUTE = {
    name: 'repayConfirmView',
    path: 'cashier/repay/confirm',
    meta: { name: '应收执行单', keepAlive: true, uid: 'repayConfirmView', isAp: false },
    component: () =>
        import ('@/views/cashier/repay/confirm')
}

export const REPAY_CONFIRM_DETAIL_VIEW_ROUTE = {
    name: 'repayConfirmDetailView',
    path: 'cashier/repay/confirm/detail/:id',
    meta: { name: '应收执行单详情', keepAlive: true, uid: 'repayConfirmDetailView' },
    component: () =>
        import ('@/views/cashier/repay/confirm/detail')
}

// 应付执行单
export const PAY_CONFIRM_VIEW_ROUTE = {
    name: 'payConfirmView',
    path: 'cashier/pay/confirm',
    meta: { name: '应付执行单', keepAlive: true, uid: 'payConfirmView', isAp: true },
    component: () =>
        import ('@/views/cashier/pay/confirm')
}

export const PAY_CONFIRM_DETAIL_VIEW_ROUTE = {
    name: 'payConfirmDetailView',
    path: 'cashier/pay/confirm/detail/:id',
    meta: { name: '应付执行单详情', keepAlive: true, uid: 'payConfirmDetailView' },
    component: () =>
        import ('@/views/cashier/pay/confirm/detail')
}

// 付款单
export const PAY_VIEW_ROUTE = {
    name: 'payView',
    path: 'cashier/pay',
    meta: { name: '付款单', keepAlive: true, uid: 'payView', isAp: true },
    component: () =>
        import ('@/views/cashier/pay')
}

export const PAY_ADD_VIEW_ROUTE = {
    name: 'payAddView',
    path: 'cashier/pay/add',
    meta: { name: '付款单新增', keepAlive: true, uid: 'payAddView' },
    component: () =>
        import ('@/views/cashier/pay/add')
}

export const PAY_DETAIL_VIEW_ROUTE = {
    name: 'payDetailView',
    path: 'cashier/pay/detail/:id',
    meta: { name: '付款单详情', keepAlive: true, uid: 'payDetailView' },
    component: () =>
        import ('@/views/cashier/pay/detail')
}

// 付款确认单
export const PAY_BATCH_VIEW_ROUTE = {
    name: 'payBatchView',
    path: 'cashier/pay/batch',
    meta: { name: '付款确认单', keepAlive: true, uid: 'payBatchView', isAp: true },
    component: () =>
        import ('@/views/cashier/pay/batch')
}

export const PAY_BATCH_ADD_VIEW_ROUTE = {
    name: 'payBatchAddView',
    path: 'cashier/pay/batch/add',
    meta: { name: '付款确认单新增', keepAlive: true, uid: 'payBatchAddView' },
    component: () =>
        import ('@/views/cashier/pay/batch/add')
}

export const PAY_BATCH_DETAIL_VIEW_ROUTE = {
    name: 'payBatchDetailView',
    path: 'cashier/pay/batch/detail/:id',
    meta: { name: '付款确认单详情', keepAlive: true, uid: 'payBatchDetailView' },
    component: () =>
        import ('@/views/cashier/pay/batch/detail')
}

// 收款确认单
export const REPAY_BATCH_VIEW_ROUTE = {
    name: 'repayBatchView',
    path: 'cashier/repay/batch',
    meta: { name: '收款确认单', keepAlive: true, uid: 'repayBatchView', isAp: true },
    component: () =>
        import ('@/views/cashier/repay/batch')
}

export const REPAY_BATCH_ADD_VIEW_ROUTE = {
    name: 'repayBatchAddView',
    path: 'cashier/repay/batch/add',
    meta: { name: '收款确认单新增', keepAlive: true, uid: 'repayBatchAddView' },
    component: () =>
        import ('@/views/cashier/repay/batch/add')
}

export const REPAY_BATCH_DETAIL_VIEW_ROUTE = {
    name: 'repayBatchDetailView',
    path: 'cashier/repay/batch/detail/:id',
    meta: { name: '收款确认单详情', keepAlive: true, uid: 'repayBatchDetailView' },
    component: () =>
        import ('@/views/cashier/repay/batch/detail')
}

// 收款单
export const REPAY_VIEW_ROUTE = {
    name: 'repayView',
    path: 'cashier/repay',
    meta: { name: '收款单', keepAlive: true, uid: 'repayView', isAp: false },
    component: () =>
        import ('@/views/cashier/repay')
}

export const REPAY_ADD_VIEW_ROUTE = {
    name: 'repayAddView',
    path: 'cashier/repay/add',
    meta: { name: '收款单新增', keepAlive: true, uid: 'repayAddView' },
    component: () =>
        import ('@/views/cashier/repay/add')
}

export const REPAY_DETAIL_VIEW_ROUTE = {
    name: 'repayDetailView',
    path: 'cashier/repay/detail/:id',
    meta: { name: '收款单详情', keepAlive: true, uid: 'repayDetailView' },
    component: () =>
        import ('@/views/cashier/repay/detail')
}
import router from '@/router'
import { DASHBOARD_VIEW_ROUTE } from '@/router/routeMetaInfo'
export function toView(routeData) {
    if (router) {
        router.push(routeData)
    }
}
export default {
    state: () => {
        return {
            selectedTab: DASHBOARD_VIEW_ROUTE,
            tabsData: []
        }
    },
    getters: {
        getKeepAliveTabs: state => {
            const keepAliveTabs = []
            state.tabsData.forEach(item => item.meta.keepAlive ? keepAliveTabs.push(item.name) : '')
            return keepAliveTabs
        }
    },
    mutations: {
        addTab(state, tab) {
            console.log('addTab begin', JSON.parse(JSON.stringify(state)), JSON.parse(JSON.stringify(tab)))
            if (tab.meta.uid !== DASHBOARD_VIEW_ROUTE.meta.uid) {
                let flag = true
                for (let i = 0; i < state.tabsData.length; i++) {
                    const t = state.tabsData[i]
                    if (tab.meta.uid === t.meta.uid) {
                        flag = false
                        break
                    }
                }
                console.log('flag', flag)
                if (flag) {
                    state.tabsData.push(tab)
                }
                console.log('addTab end', JSON.parse(JSON.stringify(state)), JSON.parse(JSON.stringify(tab)))
            }
            state.selectedTab = tab
            console.log('end1')
            toView(tab)
            console.log('end2')
        },
        deleteTab(state, tab) {
            console.log('deleteTab', state, tab)
            // 1.删除tab
            let k = 0
            for (let i = 0; i < state.tabsData.length; i++) {
                if (state.tabsData[i].meta.uid === tab.meta.uid) {
                    state.tabsData.splice(i, 1)
                    k = i
                    break
                }
            }

            // 2.使前面的tab选中
            if (tab.meta.uid === state.selectedTab.meta.uid) {
                let item = {}
                if (k === 0) {
                    item = DASHBOARD_VIEW_ROUTE
                } else {
                    item = state.tabsData[k - 1]
                }
                state.selectedTab = item
                toView(item)
            }
        },
        resetTab(state) {
            console.log('resetTab', state)
            state.tabsData = []
        }
    },
    actions: {
        addTab(context, tab) {
            context.commit('addTab', tab)
        },
        deleteTab(context, tab) {
            context.commit('deleteTab', tab)
        },
        resetTab(context) {
            context.commit('resetTab')
        }
    }
}
import router from '@/router'

export function toView(routeData) {
    router.push(routeData)
}

export const DASHBOARD_VIEW_ROUTE = {
    name: 'dashboardView',
    path: 'dashboard',
    meta: { name: '首页', keepAlive: true, uid: 'dashboardView' },
    component: () =>
        import ('@/views/dashboard')
}

export const LOGIN_VIEW_ROUTE = {
    name: 'loginView',
    path: '/login',
    meta: { name: '登录', keepAlive: true, uid: 'loginView' },
    component: () =>
        import ('@/views/login')
}

// 组织
export const COMPANY_VIEW_ROUTE = {
    name: 'companyView',
    path: 'company',
    meta: { name: '组织列表', keepAlive: true, uid: 'companyView' },
    component: () =>
        import ('@/views/settings/organization/company')
}

export const COMPANY_ADD_VIEW_ROUTE = {
    name: 'companyAddView',
    path: 'company/add',
    meta: { name: '组织新增', keepAlive: true, uid: 'companyAddView' },
    component: () =>
        import ('@/views/settings/organization/company/add')
}

export const COMPANY_DETAIL_VIEW_ROUTE = {
    name: 'companyDetailView',
    path: 'company/detail/:id',
    meta: { name: '组织查看', keepAlive: true, uid: 'companyDetailView' },
    component: () =>
        import ('@/views/settings/organization/company/detail')
}

export const COMPANY_EDIT_VIEW_ROUTE = {
    name: 'companyEditView',
    path: 'company/edit/:id',
    meta: { name: '组织编辑', keepAlive: true, uid: 'companyEditView' },
    component: () =>
        import ('@/views/settings/organization/company/edit')
}

// 部门
export const DEPARTMENT_VIEW_ROUTE = {
    name: 'departmentView',
    path: 'department',
    meta: { name: '部门列表', keepAlive: true, uid: 'departmentView' },
    component: () =>
        import ('@/views/settings/organization/department')
}

export const DEPARTMENT_ADD_VIEW_ROUTE = {
    name: 'departmentAddView',
    path: 'department/add',
    meta: { name: '部门新增', keepAlive: true, uid: 'departmentAddView' },
    component: () =>
        import ('@/views/settings/organization/department/add')
}

export const DEPARTMENT_EDIT_VIEW_ROUTE = {
    name: 'departmentEditView',
    path: 'department/edit/:id',
    meta: { name: '部门编辑', keepAlive: true, uid: 'departmentEditView' },
    component: () =>
        import ('@/views/settings/organization/department/edit')
}

export const DEPARTMENT_DETAIL_VIEW_ROUTE = {
    name: 'departmentDetailView',
    path: 'department/detail/:id',
    meta: { name: '部门查看', keepAlive: true, uid: 'departmentDetailView' },
    component: () =>
        import ('@/views/settings/organization/department/detail')
}

// 职位
export const POSITION_VIEW_ROUTE = {
    name: 'positionView',
    path: 'position',
    meta: { name: '职位列表', keepAlive: true, uid: 'positionView' },
    component: () =>
        import ('@/views/settings/organization/position')
}

export const POSITION_ADD_VIEW_ROUTE = {
    name: 'positionAddView',
    path: 'position/add',
    meta: { name: '职位新增', keepAlive: true, uid: 'positionAddView' },
    component: () =>
        import ('@/views/settings/organization/position/add')
}

export const POSITION_EDIT_VIEW_ROUTE = {
    name: 'positionEditView',
    path: 'position/edit/:id',
    meta: { name: '职位编辑', keepAlive: true, uid: 'positionEditView' },
    component: () =>
        import ('@/views/settings/organization/position/edit')
}

export const POSITION_DETAIL_VIEW_ROUTE = {
    name: 'positionDetailView',
    path: 'position/detail/:id',
    meta: { name: '职位查看', keepAlive: true, uid: 'positionDetailView' },
    component: () =>
        import ('@/views/settings/organization/position/detail')
}

// 员工
export const EMPLOYEE_VIEW_ROUTE = {
    name: 'employeeView',
    path: 'employee',
    meta: { name: '员工列表', keepAlive: true, uid: 'employeeView' },
    component: () =>
        import ('@/views/settings/organization/employee')
}

export const EMPLOYEE_ADD_VIEW_ROUTE = {
    name: 'employeeAddView',
    path: 'employee/add',
    meta: { name: '职员新增', keepAlive: true, uid: 'employeeAddView' },
    component: () =>
        import ('@/views/settings/organization/employee/add')
}

export const EMPLOYEE_EDIT_VIEW_ROUTE = {
    name: 'employeeEditView',
    path: 'employee/edit/:id',
    meta: { name: '职员编辑', keepAlive: true, uid: 'employeeEditView' },
    component: () =>
        import ('@/views/settings/organization/employee/edit')
}

export const EMPLOYEE_DETAIL_VIEW_ROUTE = {
    name: 'employeeDetailView',
    path: 'employee/detail/:id',
    meta: { name: '职员查看', keepAlive: true, uid: 'employeeDetailView' },
    component: () =>
        import ('@/views/settings/organization/employee/detail')
}

// 基础设置
// 结算方式 settlement_method SETTLEMENT_METHOD
export const SETTLEMENT_METHOD_VIEW_ROUTE = {
    name: 'settlementMethodView',
    path: 'settlementMethod',
    meta: { name: '结算方式', keepAlive: true, uid: 'settlementMethodView' },
    component: () =>
        import ('@/views/settings/settlement')
}

export const SETTLEMENT_METHOD_ADD_VIEW_ROUTE = {
    name: 'settlementMethodAddView',
    path: 'settlementMethod/add',
    meta: { name: '结算方式新增', keepAlive: true, uid: 'settlementMethodAddView' },
    component: () =>
        import ('@/views/settings/settlement/add')
}

export const SETTLEMENT_METHOD_EDIT_VIEW_ROUTE = {
    name: 'settlementMethodEditView',
    path: 'settlementMethod/edit/:id',
    meta: { name: '结算方式编辑', keepAlive: true, uid: 'settlementMethodEditView' },
    component: () =>
        import ('@/views/settings/settlement/edit')
}

export const SETTLEMENT_METHOD_DETAIL_VIEW_ROUTE = {
    name: 'settlementMethodDetailView',
    path: 'settlementMethod/detail/:id',
    meta: { name: '结算方式查看', keepAlive: true, uid: 'settlementMethodDetailView' },
    component: () =>
        import ('@/views/settings/settlement/detail')
}

// 假期类型 holiday HOLIDAY
export const HOLIDAY_VIEW_ROUTE = {
    name: 'holidayView',
    path: 'holiday',
    meta: { name: '假期类型', keepAlive: true, uid: 'holidayView' },
    component: () =>
        import ('@/views/settings/holiday')
}

export const HOLIDAY_ADD_VIEW_ROUTE = {
    name: 'holidayAddView',
    path: 'holiday/add',
    meta: { name: '假期类型新增', keepAlive: true, uid: 'holidayAddView' },
    component: () =>
        import ('@/views/settings/holiday/add')
}

export const HOLIDAY_EDIT_VIEW_ROUTE = {
    name: 'holidayEditView',
    path: 'holiday/edit/:id',
    meta: { name: '假期类型编辑', keepAlive: true, uid: 'holidayEditView' },
    component: () =>
        import ('@/views/settings/holiday/edit')
}

export const HOLIDAY_DETAIL_VIEW_ROUTE = {
    name: 'holidayDetailView',
    path: 'holiday/detail/:id',
    meta: { name: '假期类型查看', keepAlive: true, uid: 'holidayDetailView' },
    component: () =>
        import ('@/views/settings/holiday/detail')
}

// 股东类型 shareholderType SHAREHOLDER_TYPE
export const SHAREHOLDER_TYPE_VIEW_ROUTE = {
    name: 'shareholderTypeView',
    path: 'shareholderType',
    meta: { name: '股东类型', keepAlive: true, uid: 'shareholderTypeView' },
    component: () =>
        import ('@/views/settings/shareholderType')
}

export const SHAREHOLDER_TYPE_ADD_VIEW_ROUTE = {
    name: 'shareholderTypeAddView',
    path: 'shareholderType/add',
    meta: { name: '股东类型新增', keepAlive: true, uid: 'shareholderTypeAddView' },
    component: () =>
        import ('@/views/settings/shareholderType/add')
}

export const SHAREHOLDER_TYPE_EDIT_VIEW_ROUTE = {
    name: 'shareholderTypeEditView',
    path: 'shareholderType/edit/:id',
    meta: { name: '股东类型编辑', keepAlive: true, uid: 'shareholderTypeEditView' },
    component: () =>
        import ('@/views/settings/shareholderType/edit')
}

export const SHAREHOLDER_TYPE_DETAIL_VIEW_ROUTE = {
    name: 'shareholderTypeDetailView',
    path: 'shareholderType/detail/:id',
    meta: { name: '股东类型查看', keepAlive: true, uid: 'shareholderTypeDetailView' },
    component: () =>
        import ('@/views/settings/shareholderType/detail')
}

// 员工类型 employeeType EMPLOYEE_TYPE
export const EMPLOYEE_TYPE_VIEW_ROUTE = {
    name: 'employeeTypeView',
    path: 'employeeType',
    meta: { name: '员工类型', keepAlive: true, uid: 'employeeTypeView' },
    component: () =>
        import ('@/views/settings/employeeType')
}

export const EMPLOYEE_TYPE_ADD_VIEW_ROUTE = {
    name: 'employeeTypeAddView',
    path: 'employeeType/add',
    meta: { name: '员工类型新增', keepAlive: true, uid: 'employeeTypeAddView' },
    component: () =>
        import ('@/views/settings/employeeType/add')
}

export const EMPLOYEE_TYPE_EDIT_VIEW_ROUTE = {
    name: 'employeeTypeEditView',
    path: 'employeeType/edit/:id',
    meta: { name: '员工类型编辑', keepAlive: true, uid: 'employeeTypeEditView' },
    component: () =>
        import ('@/views/settings/employeeType/edit')
}

export const EMPLOYEE_TYPE_DETAIL_VIEW_ROUTE = {
    name: 'employeeTypeDetailView',
    path: 'employeeType/detail/:id',
    meta: { name: '员工类型查看', keepAlive: true, uid: 'employeeTypeDetailView' },
    component: () =>
        import ('@/views/settings/employeeType/detail')
}

// 员工状态 employeeState EMPLOYEE_STATE
export const EMPLOYEE_STATE_VIEW_ROUTE = {
    name: 'employeeStateView',
    path: 'employeeState',
    meta: { name: '员工状态', keepAlive: true, uid: 'employeeStateView' },
    component: () =>
        import ('@/views/settings/employeeState')
}

export const EMPLOYEE_STATE_ADD_VIEW_ROUTE = {
    name: 'employeeStateAddView',
    path: 'employeeState/add',
    meta: { name: '员工状态新增', keepAlive: true, uid: 'employeeStateAddView' },
    component: () =>
        import ('@/views/settings/employeeState/add')
}

export const EMPLOYEE_STATE_EDIT_VIEW_ROUTE = {
    name: 'employeeStateEditView',
    path: 'employeeState/edit/:id',
    meta: { name: '员工状态编辑', keepAlive: true, uid: 'employeeStateEditView' },
    component: () =>
        import ('@/views/settings/employeeState/edit')
}

export const EMPLOYEE_STATE_DETAIL_VIEW_ROUTE = {
    name: 'employeeStateDetailView',
    path: 'employeeState/detail/:id',
    meta: { name: '员工状态查看', keepAlive: true, uid: 'employeeStateDetailView' },
    component: () =>
        import ('@/views/settings/employeeState/detail')
}

// 计量单位分组 unitGroup UNIT_GROUP
export const UNIT_GROUP_VIEW_ROUTE = {
    name: 'unitGroupView',
    path: 'unitGroup',
    meta: { name: '计量单位分组', keepAlive: true, uid: 'unitGroupView' },
    component: () =>
        import ('@/views/settings/unitGroup')
}

export const UNIT_GROUP_ADD_VIEW_ROUTE = {
    name: 'unitGroupAddView',
    path: 'unitGroup/add',
    meta: { name: '计量单位分组新增', keepAlive: true, uid: 'unitGroupAddView' },
    component: () =>
        import ('@/views/settings/unitGroup/add')
}

export const UNIT_GROUP_EDIT_VIEW_ROUTE = {
    name: 'unitGroupEditView',
    path: 'unitGroup/edit/:id',
    meta: { name: '计量单位分组编辑', keepAlive: true, uid: 'unitGroupEditView' },
    component: () =>
        import ('@/views/settings/unitGroup/edit')
}

export const UNIT_GROUP_DETAIL_VIEW_ROUTE = {
    name: 'unitGroupDetailView',
    path: 'unitGroup/detail/:id',
    meta: { name: '计量单位分组查看', keepAlive: true, uid: 'unitGroupDetailView' },
    component: () =>
        import ('@/views/settings/unitGroup/detail')
}

// 计量单位 unit UNIT
export const UNIT_VIEW_ROUTE = {
    name: 'unitView',
    path: 'unit',
    meta: { name: '计量单位', keepAlive: true, uid: 'unitView' },
    component: () =>
        import ('@/views/settings/unit')
}

export const UNIT_ADD_VIEW_ROUTE = {
    name: 'unitAddView',
    path: 'unit/add',
    meta: { name: '计量单位新增', keepAlive: true, uid: 'unitAddView' },
    component: () =>
        import ('@/views/settings/unit/add')
}

export const UNIT_EDIT_VIEW_ROUTE = {
    name: 'unitEditView',
    path: 'unit/edit/:id',
    meta: { name: '计量单位编辑', keepAlive: true, uid: 'unitEditView' },
    component: () =>
        import ('@/views/settings/unit/edit')
}

export const UNIT_DETAIL_VIEW_ROUTE = {
    name: 'unitDetailView',
    path: 'unit/detail/:id',
    meta: { name: '计量单位查看', keepAlive: true, uid: 'unitDetailView' },
    component: () =>
        import ('@/views/settings/unit/detail')
}

// 存货分类 stockCategory STOCK_CATEGORY
export const STOCK_CATEGORY_VIEW_ROUTE = {
    name: 'stockCategoryView',
    path: 'stockCategory',
    meta: { name: '存货分类', keepAlive: true, uid: 'stockCategoryView' },
    component: () =>
        import ('@/views/settings/stockCategory')
}

export const STOCK_CATEGORY_ADD_VIEW_ROUTE = {
    name: 'stockCategoryAddView',
    path: 'stockCategory/add',
    meta: { name: '存货分类新增', keepAlive: true, uid: 'stockCategoryAddView' },
    component: () =>
        import ('@/views/settings/stockCategory/add')
}

export const STOCK_CATEGORY_EDIT_VIEW_ROUTE = {
    name: 'stockCategoryEditView',
    path: 'stockCategory/edit/:id',
    meta: { name: '存货分类编辑', keepAlive: true, uid: 'stockCategoryEditView' },
    component: () =>
        import ('@/views/settings/stockCategory/edit')
}

export const STOCK_CATEGORY_DETAIL_VIEW_ROUTE = {
    name: 'stockCategoryDetailView',
    path: 'stockCategory/detail/:id',
    meta: { name: '存货分类查看', keepAlive: true, uid: 'stockCategoryDetailView' },
    component: () =>
        import ('@/views/settings/stockCategory/detail')
}

// 存货规格 stockSpecs STOCK_SPECS
export const STOCK_SPECS_VIEW_ROUTE = {
    name: 'stockSpecsView',
    path: 'stockSpecs',
    meta: { name: '存货规格', keepAlive: true, uid: 'stockSpecsView' },
    component: () =>
        import ('@/views/settings/stockSpecs')
}

export const STOCK_SPECS_ADD_VIEW_ROUTE = {
    name: 'stockSpecsAddView',
    path: 'stockSpecs/add',
    meta: { name: '存货规格新增', keepAlive: true, uid: 'stockSpecsAddView' },
    component: () =>
        import ('@/views/settings/stockSpecs/add')
}

export const STOCK_SPECS_EDIT_VIEW_ROUTE = {
    name: 'stockSpecsEditView',
    path: 'stockSpecs/edit/:id',
    meta: { name: '存货规格编辑', keepAlive: true, uid: 'stockSpecsEditView' },
    component: () =>
        import ('@/views/settings/stockSpecs/edit')
}

export const STOCK_SPECS_DETAIL_VIEW_ROUTE = {
    name: 'stockSpecsDetailView',
    path: 'stockSpecs/detail/:id',
    meta: { name: '存货规格查看', keepAlive: true, uid: 'stockSpecsDetailView' },
    component: () =>
        import ('@/views/settings/stockSpecs/detail')
}

// 存货 stock STOCK
export const STOCK_VIEW_ROUTE = {
    name: 'stockView',
    path: 'stock',
    meta: { name: '存货', keepAlive: true, uid: 'stockView' },
    component: () =>
        import ('@/views/settings/stock')
}

export const STOCK_ADD_VIEW_ROUTE = {
    name: 'stockAddView',
    path: 'stock/add',
    meta: { name: '存货新增', keepAlive: true, uid: 'stockAddView' },
    component: () =>
        import ('@/views/settings/stock/add')
}

export const STOCK_EDIT_VIEW_ROUTE = {
    name: 'stockEditView',
    path: 'stock/edit/:id',
    meta: { name: '存货编辑', keepAlive: true, uid: 'stockEditView' },
    component: () =>
        import ('@/views/settings/stock/edit')
}

export const STOCK_DETAIL_VIEW_ROUTE = {
    name: 'stockDetailView',
    path: 'stock/detail/:id',
    meta: { name: '存货查看', keepAlive: true, uid: 'stockDetailView' },
    component: () =>
        import ('@/views/settings/stock/detail')
}

// 仓库 warehouse WAREHOUSE
export const WAREHOUSE_VIEW_ROUTE = {
    name: 'warehouseView',
    path: 'warehouse',
    meta: { name: '仓库', keepAlive: true, uid: 'warehouseView' },
    component: () =>
        import ('@/views/settings/warehouse')
}

export const WAREHOUSE_ADD_VIEW_ROUTE = {
    name: 'warehouseAddView',
    path: 'warehouse/add',
    meta: { name: '仓库新增', keepAlive: true, uid: 'warehouseAddView' },
    component: () =>
        import ('@/views/settings/warehouse/add')
}

export const WAREHOUSE_EDIT_VIEW_ROUTE = {
    name: 'warehouseEditView',
    path: 'warehouse/edit/:id',
    meta: { name: '仓库编辑', keepAlive: true, uid: 'warehouseEditView' },
    component: () =>
        import ('@/views/settings/warehouse/edit')
}

export const WAREHOUSE_DETAIL_VIEW_ROUTE = {
    name: 'warehouseDetailView',
    path: 'warehouse/detail/:id',
    meta: { name: '仓库查看', keepAlive: true, uid: 'warehouseDetailView' },
    component: () =>
        import ('@/views/settings/warehouse/detail')
}

// 区域 area AREA
export const AREA_VIEW_ROUTE = {
    name: 'areaView',
    path: 'area',
    meta: { name: '区域', keepAlive: true, uid: 'areaView' },
    component: () =>
        import ('@/views/settings/area')
}

export const AREA_ADD_VIEW_ROUTE = {
    name: 'areaAddView',
    path: 'area/add',
    meta: { name: '区域新增', keepAlive: true, uid: 'areaAddView' },
    component: () =>
        import ('@/views/settings/area/add')
}

export const AREA_EDIT_VIEW_ROUTE = {
    name: 'areaEditView',
    path: 'area/edit/:id',
    meta: { name: '区域编辑', keepAlive: true, uid: 'areaEditView' },
    component: () =>
        import ('@/views/settings/area/edit')
}

export const AREA_DETAIL_VIEW_ROUTE = {
    name: 'areaDetailView',
    path: 'area/detail/:id',
    meta: { name: '区域查看', keepAlive: true, uid: 'areaDetailView' },
    component: () =>
        import ('@/views/settings/area/detail')
}

// 保险险种 insurance INSURANCE
export const INSURANCE_VIEW_ROUTE = {
    name: 'insuranceView',
    path: 'insurance',
    meta: { name: '保险险种', keepAlive: true, uid: 'insuranceView' },
    component: () =>
        import ('@/views/settings/insurance')
}

export const INSURANCE_ADD_VIEW_ROUTE = {
    name: 'insuranceAddView',
    path: 'insurance/add',
    meta: { name: '保险险种新增', keepAlive: true, uid: 'insuranceAddView' },
    component: () =>
        import ('@/views/settings/insurance/add')
}

export const INSURANCE_EDIT_VIEW_ROUTE = {
    name: 'insuranceEditView',
    path: 'insurance/edit/:id',
    meta: { name: '保险险种编辑', keepAlive: true, uid: 'insuranceEditView' },
    component: () =>
        import ('@/views/settings/insurance/edit')
}

export const INSURANCE_DETAIL_VIEW_ROUTE = {
    name: 'insuranceDetailView',
    path: 'insurance/detail/:id',
    meta: { name: '保险险种查看', keepAlive: true, uid: 'insuranceDetailView' },
    component: () =>
        import ('@/views/settings/insurance/detail')
}

// 科目
export const COMMON_SUBJECT_VIEW_ROUTE = {
    name: 'commonSubjectView',
    path: 'commonSubject',
    meta: { name: '科目', keepAlive: true, uid: 'commonSubjectView' },
    component: () =>
        import ('@/views/settings/subject')
}

// 往来单位分类 institutionCategory INSTITUTION_CATEGORY
export const INSTITUTION_CATEGORY_VIEW_ROUTE = {
    name: 'institutionCategoryView',
    path: 'institutionCategory',
    meta: { name: '往来单位分类', keepAlive: true, uid: 'institutionCategoryView' },
    component: () =>
        import ('@/views/settings/institution/category')
}

export const INSTITUTION_CATEGORY_ADD_VIEW_ROUTE = {
    name: 'institutionCategoryAddView',
    path: 'institutionCategory/add',
    meta: { name: '往来单位分类新增', keepAlive: true, uid: 'institutionCategoryAddView' },
    component: () =>
        import ('@/views/settings/institution/category/add')
}

export const INSTITUTION_CATEGORY_EDIT_VIEW_ROUTE = {
    name: 'institutionCategoryEditView',
    path: 'institutionCategory/edit/:id',
    meta: { name: '往来单位分类编辑', keepAlive: true, uid: 'institutionCategoryEditView' },
    component: () =>
        import ('@/views/settings/institution/category/edit')
}

export const INSTITUTION_CATEGORY_DETAIL_VIEW_ROUTE = {
    name: 'institutionCategoryDetailView',
    path: 'institutionCategory/detail/:id',
    meta: { name: '往来单位分类查看', keepAlive: true, uid: 'institutionCategoryDetailView' },
    component: () =>
        import ('@/views/settings/institution/category/detail')
}

// 往来单位
// 往来单位 institution INSTITUTION
export const INSTITUTION_VIEW_ROUTE = {
    name: 'institutionView',
    path: 'institution',
    meta: { name: '往来单位', keepAlive: true, uid: 'institutionView' },
    component: () =>
        import ('@/views/settings/institution')
}

export const INSTITUTION_ADD_VIEW_ROUTE = {
    name: 'institutionAddView',
    path: 'institution/add',
    meta: { name: '往来单位新增', keepAlive: true, uid: 'institutionAddView' },
    component: () =>
        import ('@/views/settings/institution/add')
}

export const INSTITUTION_EDIT_VIEW_ROUTE = {
    name: 'institutionEditView',
    path: 'institution/edit/:id',
    meta: { name: '往来单位编辑', keepAlive: true, uid: 'institutionEditView' },
    component: () =>
        import ('@/views/settings/institution/edit')
}

export const INSTITUTION_DETAIL_VIEW_ROUTE = {
    name: 'institutionDetailView',
    path: 'institution/detail/:id',
    meta: { name: '往来单位查看', keepAlive: true, uid: 'institutionDetailView' },
    component: () =>
        import ('@/views/settings/institution/detail')
}

// 账户
export const FUND_ACCOUNT_VIEW_ROUTE = {
    name: 'fundAccountView',
    path: 'fundAccount',
    meta: { name: '账户', keepAlive: true, uid: 'fundAccountView' },
    component: () =>
        import ('@/views/settings/fundAccount')
}

export const FUND_ACCOUNT_ADD_VIEW_ROUTE = {
    name: 'fundAccountAddView',
    path: 'fundAccount/add',
    meta: { name: '账户新增', keepAlive: true, uid: 'fundAccountAddView' },
    component: () =>
        import ('@/views/settings/fundAccount/add')
}

export const FUND_ACCOUNT_EDIT_VIEW_ROUTE = {
    name: 'fundAccountEditView',
    path: 'fundAccount/edit/:id',
    meta: { name: '账户编辑', keepAlive: true, uid: 'fundAccountEditView' },
    component: () =>
        import ('@/views/settings/fundAccount/edit')
}

export const FUND_ACCOUNT_DETAIL_VIEW_ROUTE = {
    name: 'fundAccountDetailView',
    path: 'fundAccount/detail/:id',
    meta: { name: '账户查看', keepAlive: true, uid: 'fundAccountDetailView' },
    component: () =>
        import ('@/views/settings/fundAccount/detail')
}

// 账簿 accountingBook ACCOUNTING_BOOK
export const ACCOUNTING_BOOK_VIEW_ROUTE = {
    name: 'accountingBookView',
    path: 'accountingBook',
    meta: { name: '账簿', keepAlive: true, uid: 'accountingBookView' },
    component: () =>
        import ('@/views/finance/accountingBook')
}

export const ACCOUNTING_BOOK_ADD_VIEW_ROUTE = {
    name: 'accountingBookAddView',
    path: 'accountingBook/add',
    meta: { name: '账簿新增', keepAlive: true, uid: 'accountingBookAddView' },
    component: () =>
        import ('@/views/finance/accountingBook/add')
}

export const ACCOUNTING_BOOK_EDIT_VIEW_ROUTE = {
    name: 'accountingBookEditView',
    path: 'accountingBook/edit/:id',
    meta: { name: '账簿编辑', keepAlive: true, uid: 'accountingBookEditView' },
    component: () =>
        import ('@/views/finance/accountingBook/edit')
}

export const ACCOUNTING_BOOK_DETAIL_VIEW_ROUTE = {
    name: 'accountingBookDetailView',
    path: 'accountingBook/detail/:id',
    meta: { name: '账簿查看', keepAlive: true, uid: 'accountingBookDetailView' },
    component: () =>
        import ('@/views/finance/accountingBook/detail')
}

// 科目表 subjectTable SUBJECT_TABLE
export const SUBJECT_TABLE_VIEW_ROUTE = {
    name: 'subjectTableView',
    path: 'subjectTable',
    meta: { name: '科目表', keepAlive: true, uid: 'subjectTableView' },
    component: () =>
        import ('@/views/finance/subjectTable')
}

export const SUBJECT_TABLE_ADD_VIEW_ROUTE = {
    name: 'subjectTableAddView',
    path: 'subjectTable/add',
    meta: { name: '科目表新增', keepAlive: true, uid: 'subjectTableAddView' },
    component: () =>
        import ('@/views/finance/subjectTable/add')
}

export const SUBJECT_TABLE_EDIT_VIEW_ROUTE = {
    name: 'subjectTableEditView',
    path: 'subjectTable/edit/:id',
    meta: { name: '科目表编辑', keepAlive: true, uid: 'subjectTableEditView' },
    component: () =>
        import ('@/views/finance/subjectTable/edit')
}

export const SUBJECT_TABLE_DETAIL_VIEW_ROUTE = {
    name: 'subjectTableDetailView',
    path: 'subjectTable/detail/:id',
    meta: { name: '科目表查看', keepAlive: true, uid: 'subjectTableDetailView' },
    component: () =>
        import ('@/views/finance/subjectTable/detail')
}

// 科目 subject SUBJECT
export const SUBJECT_VIEW_ROUTE = {
    name: 'subjectView',
    path: 'subject',
    meta: { name: '科目', keepAlive: true, uid: 'subjectView' },
    component: () =>
        import ('@/views/finance/subject')
}

export const SUBJECT_ADD_VIEW_ROUTE = {
    name: 'subjectAddView',
    path: 'subject/add',
    meta: { name: '科目新增', keepAlive: true, uid: 'subjectAddView' },
    component: () =>
        import ('@/views/finance/subject/add')
}

export const SUBJECT_EDIT_VIEW_ROUTE = {
    name: 'subjectEditView',
    path: 'subject/edit/:id',
    meta: { name: '科目编辑', keepAlive: true, uid: 'subjectEditView' },
    component: () =>
        import ('@/views/finance/subject/edit')
}

export const SUBJECT_DETAIL_VIEW_ROUTE = {
    name: 'subjectDetailView',
    path: 'subject/detail/:id',
    meta: { name: '科目查看', keepAlive: true, uid: 'subjectDetailView' },
    component: () =>
        import ('@/views/finance/subject/detail')
}

// 汇率方案
export const EXCHANGE_PLAN_DETAIL_VIEW_ROUTE = {
    name: 'exchangePlanDetailView',
    path: 'exchangePlan/Detail',
    meta: { name: '汇率方案查看', keepAlive: true, uid: 'exchangePlanDetailView' },
    component: () =>
        import ('@/views/finance/exchangePlan/detail')
}

export const EXCHANGE_PLAN_EDIT_VIEW_ROUTE = {
    name: 'exchangePlanEditView',
    path: 'exchangePlan/Edit',
    meta: { name: '汇率方案编辑', keepAlive: true, uid: 'exchangePlanEditView' },
    component: () =>
        import ('@/views/finance/exchangePlan/edit')
}

// 汇率设置
export const EXCHANGE_RATE_EDIT_VIEW_ROUTE = {
    name: 'exchangeRateEditView',
    path: 'exchangeRate/Edit',
    meta: { name: '汇率编辑', keepAlive: true, uid: 'exchangeRateEditView' },
    component: () =>
        import ('@/views/finance/exchangeRate/edit')
}

// 凭证设置
export const VOUCHER_SETTINGS_EDIT_VIEW_ROUTE = {
    name: 'voucherSettingsEditView',
    path: 'voucher/settings/edit',
    meta: { name: '凭证设置编辑', keepAlive: true, uid: 'voucherSettingsEditView' },
    component: () =>
        import ('@/views/finance/voucher/settings/edit')
}

export const VOUCHER_SETTINGS_DETAIL_VIEW_ROUTE = {
    name: 'voucherSettingsDetailView',
    path: 'voucher/settings/detail',
    meta: { name: '凭证设置查看', keepAlive: true, uid: 'voucherSettingsDetailView' },
    component: () =>
        import ('@/views/finance/voucher/settings/detail')
}

// 凭证 voucher VOUCHER
export const VOUCHER_VIEW_ROUTE = {
    name: 'voucherView',
    path: 'voucher',
    meta: { name: '凭证查询', keepAlive: true, uid: 'voucherView' },
    component: () =>
        import ('@/views/finance/voucher')
}

export const VOUCHER_ADD_VIEW_ROUTE = {
    name: 'voucherAddView',
    path: 'voucher/add',
    meta: { name: '凭证录入', keepAlive: true, uid: 'voucherAddView' },
    component: () =>
        import ('@/views/finance/voucher/add')
}

export const VOUCHER_EDIT_VIEW_ROUTE = {
    name: 'voucherEditView',
    path: 'voucher/edit/:id',
    meta: { name: '凭证编辑', keepAlive: true, uid: 'voucherEditView' },
    component: () =>
        import ('@/views/finance/voucher/edit')
}

export const VOUCHER_DETAIL_VIEW_ROUTE = {
    name: 'voucherDetailView',
    path: 'voucher/detail/:id',
    meta: { name: '凭证查看', keepAlive: true, uid: 'voucherDetailView' },
    component: () =>
        import ('@/views/finance/voucher/detail')
}

// 凭证 - 摘要
export const VOUCHER_ABSTRACT_CATEGORY_VIEW_ROUTE = {
    name: 'voucherAbstractCategoryView',
    path: 'voucher/abstract/category',
    meta: { name: '摘要分类', keepAlive: true, uid: 'voucherAbstractCategoryView' },
    component: () =>
        import ('@/views/finance/voucher/abstract/category')
}

export const VOUCHER_ABSTRACT_CATEGORY_ADD_VIEW_ROUTE = {
    name: 'voucherAbstractCategoryAddView',
    path: 'voucher/abstract/category/add',
    meta: { name: '摘要分类新增', keepAlive: true, uid: 'voucherAbstractCategoryAddView' },
    component: () =>
        import ('@/views/finance/voucher/abstract/category/add')
}

export const VOUCHER_ABSTRACT_CATEGORY_EDIT_VIEW_ROUTE = {
    name: 'voucherAbstractCategoryEditView',
    path: 'voucher/abstract/category/edit/:id',
    meta: { name: '摘要分类编辑', keepAlive: true, uid: 'voucherAbstractCategoryEditView' },
    component: () =>
        import ('@/views/finance/voucher/abstract/category/edit')
}

export const VOUCHER_ABSTRACT_CATEGORY_DETAIL_VIEW_ROUTE = {
    name: 'voucherAbstractCategoryDetailView',
    path: 'voucher/abstract/category/detail/:id',
    meta: { name: '摘要分类详情', keepAlive: true, uid: 'voucherAbstractCategoryDetailView' },
    component: () =>
        import ('@/views/finance/voucher/abstract/category/detail')
}

// 摘要库
export const VOUCHER_ABSTRACT_VIEW_ROUTE = {
    name: 'voucherAbstractView',
    path: 'voucher/abstract',
    meta: { name: '摘要库', keepAlive: true, uid: 'voucherAbstractView' },
    component: () =>
        import ('@/views/finance/voucher/abstract')
}

export const VOUCHER_ABSTRACT_ADD_VIEW_ROUTE = {
    name: 'voucherAbstractAddView',
    path: 'voucher/abstract/add',
    meta: { name: '摘要库新增', keepAlive: true, uid: 'voucherAbstractAddView' },
    component: () =>
        import ('@/views/finance/voucher/abstract/add')
}

export const VOUCHER_ABSTRACT_EDIT_VIEW_ROUTE = {
    name: 'voucherAbstractEditView',
    path: 'voucher/abstract/edit/:id',
    meta: { name: '摘要库编辑', keepAlive: true, uid: 'voucherAbstractEditView' },
    component: () =>
        import ('@/views/finance/voucher/abstract/edit')
}

export const VOUCHER_ABSTRACT_DETAIL_VIEW_ROUTE = {
    name: 'voucherAbstractDetailView',
    path: 'voucher/abstract/detail/:id',
    meta: { name: '摘要库详情', keepAlive: true, uid: 'voucherAbstractDetailView' },
    component: () =>
        import ('@/views/finance/voucher/abstract/detail')
}

// 科目明细账 voucherItem VOUCHER_ITEM
export const VOUCHER_ITEM_VIEW_ROUTE = {
    name: 'voucherItemView',
    path: 'voucherItem',
    meta: { name: '明细账', keepAlive: true, uid: 'voucherItemView' },
    component: () =>
        import ('@/views/finance/voucher/item')
}

// 科目辅助明细账
export const VOUCHER_ITEM_ASSIST_ITEM_VIEW_ROUTE = {
    name: 'voucherItemAssistItemView',
    path: 'voucherItem/assistItem',
    meta: { name: '多维度明细账', keepAlive: true, uid: 'voucherItemAssistItemView' },
    component: () =>
        import ('@/views/finance/voucher/item/assistItem')
}

// 科目与多维度速查表
export const VOUCHER_ITEM_FAST_VIEW_ROUTE = {
    name: 'voucherItemFastView',
    path: 'voucherItem/fast',
    meta: { name: '科目与多维度速查表', keepAlive: true, uid: 'voucherItemFastView' },
    component: () =>
        import ('@/views/finance/voucher/item/fast')
}

// 科目总账
export const VOUCHER_ITEM_GENERAL_LEDGER_VIEW_ROUTE = {
    name: 'voucherItemViewGeneralLedger',
    path: 'voucherItem/generalLedger',
    meta: { name: '总账', keepAlive: true, uid: 'voucherItemViewGeneralLedger' },
    component: () =>
        import ('@/views/finance/voucher/item/generalLedger')
}

// 结转
// 结转研发费用
export const CARRY_OVER_PLAN_COST_VIEW_ROUTE = {
    name: 'carryOverPlanCostView',
    path: 'carryOverPlan/cost',
    meta: { name: '结转研发费用方案列表', keepAlive: true, uid: 'carryOverPlanCostView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/cost')
}

export const CARRY_OVER_PLAN_COST_ADD_VIEW_ROUTE = {
    name: 'carryOverPlanCostAddView',
    path: 'carryOverPlan/cost/add',
    meta: { name: '结转研发费用方案新增', keepAlive: true, uid: 'carryOverPlanCostAddView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/cost/add')
}

export const CARRY_OVER_PLAN_COST_EDIT_VIEW_ROUTE = {
    name: 'carryOverPlanCostEditView',
    path: 'carryOverPlan/cost/edit/:id',
    meta: { name: '结转研发费用方案编辑', keepAlive: true, uid: 'carryOverPlanCostEditView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/cost/edit')
}

export const CARRY_OVER_PLAN_COST_DETAIL_VIEW_ROUTE = {
    name: 'carryOverPlanCostDetailView',
    path: 'carryOverPlan/cost/detail/:id',
    meta: { name: '结转研发费用方案查看', keepAlive: true, uid: 'carryOverPlanCostDetailView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/cost/detail')
}

export const CARRY_OVER_RECORD_COST_VIEW_ROUTE = {
    name: 'carryOverRecordCostView',
    path: 'carryOverRecord/cost',
    meta: { name: '结转研发费用记录列表', keepAlive: true, uid: 'carryOverRecordCostView' },
    component: () =>
        import ('@/views/finance/carryOver/record/cost')
}

export const CARRY_OVER_RECORD_COST_DETAIL_VIEW_ROUTE = {
    name: 'carryOverRecordCostDetailView',
    path: 'carryOverRecord/cost/detail/:id',
    meta: { name: '结转研发费用记录查看', keepAlive: true, uid: 'carryOverRecordCostDetailView' },
    component: () =>
        import ('@/views/finance/carryOver/record/cost/detail')
}

// 结转增值税
export const CARRY_OVER_PLAN_ADD_TAX_VIEW_ROUTE = {
    name: 'carryOverPlanAddTaxView',
    path: 'carryOverPlan/addTax',
    meta: { name: '结转增值税方案列表', keepAlive: true, uid: 'carryOverPlanAddTaxView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/addTax')
}

export const CARRY_OVER_PLAN_ADD_TAX_ADD_VIEW_ROUTE = {
    name: 'carryOverPlanAddTaxAddView',
    path: 'carryOverPlan/addTax/add',
    meta: { name: '结转增值税方案新增', keepAlive: true, uid: 'carryOverPlanAddTaxAddView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/addTax/add')
}

export const CARRY_OVER_PLAN_ADD_TAX_EDIT_VIEW_ROUTE = {
    name: 'carryOverPlanAddTaxEditView',
    path: 'carryOverPlan/addTax/edit/:id',
    meta: { name: '结转增值税方案编辑', keepAlive: true, uid: 'carryOverPlanAddTaxEditView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/addTax/edit')
}

export const CARRY_OVER_PLAN_ADD_TAX_DETAIL_VIEW_ROUTE = {
    name: 'carryOverPlanAddTaxDetailView',
    path: 'carryOverPlan/addTax/detail/:id',
    meta: { name: '结转增值税方案查看', keepAlive: true, uid: 'carryOverPlanAddTaxDetailView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/addTax/detail')
}

export const CARRY_OVER_RECORD_ADD_TAX_VIEW_ROUTE = {
    name: 'carryOverRecordAddTaxView',
    path: 'carryOverRecord/addTax',
    meta: { name: '结转增值税记录列表', keepAlive: true, uid: 'carryOverRecordAddTaxView' },
    component: () =>
        import ('@/views/finance/carryOver/record/addTax')
}

export const CARRY_OVER_RECORD_ADD_TAX_DETAIL_VIEW_ROUTE = {
    name: 'carryOverRecordAddTaxDetailView',
    path: 'carryOverRecord/addTax/detail/:id',
    meta: { name: '结转增值税记录查看', keepAlive: true, uid: 'carryOverRecordAddTaxDetailView' },
    component: () =>
        import ('@/views/finance/carryOver/record/addTax/detail')
}

// 结转损益
export const CARRY_OVER_PLAN_PROFIT_LOSS_VIEW_ROUTE = {
    name: 'carryOverPlanProfitLossView',
    path: 'carryOverPlan/profitLoss',
    meta: { name: '结转损益方案列表', keepAlive: true, uid: 'carryOverPlanProfitLossView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/profitLoss')
}

export const CARRY_OVER_PLAN_PROFIT_LOSS_ADD_VIEW_ROUTE = {
    name: 'carryOverPlanProfitLossAddView',
    path: 'carryOverPlan/profitLoss/add',
    meta: { name: '结转损益方案新增', keepAlive: true, uid: 'carryOverPlanProfitLossAddView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/profitLoss/add')
}

export const CARRY_OVER_PLAN_PROFIT_LOSS_EDIT_VIEW_ROUTE = {
    name: 'carryOverPlanProfitLossEditView',
    path: 'carryOverPlan/profitLoss/edit/:id',
    meta: { name: '结转损益方案编辑', keepAlive: true, uid: 'carryOverPlanProfitLossEditView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/profitLoss/edit')
}

export const CARRY_OVER_PLAN_PROFIT_LOSS_DETAIL_VIEW_ROUTE = {
    name: 'carryOverPlanProfitLossDetailView',
    path: 'carryOverPlan/profitLoss/detail/:id',
    meta: { name: '结转损益方案查看', keepAlive: true, uid: 'carryOverPlanProfitLossDetailView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/profitLoss/detail')
}

export const CARRY_OVER_RECORD_PROFIT_LOSS_VIEW_ROUTE = {
    name: 'carryOverRecordProfitLossView',
    path: 'carryOverRecord/profitLoss',
    meta: { name: '结转损益记录列表', keepAlive: true, uid: 'carryOverRecordProfitLossView' },
    component: () =>
        import ('@/views/finance/carryOver/record/profitLoss')
}

export const CARRY_OVER_RECORD_PROFIT_LOSS_DETAIL_VIEW_ROUTE = {
    name: 'carryOverRecordProfitLossDetailView',
    path: 'carryOverRecord/profitLoss/detail/:id',
    meta: { name: '结转损益记录查看', keepAlive: true, uid: 'carryOverRecordProfitLossDetailView' },
    component: () =>
        import ('@/views/finance/carryOver/record/profitLoss/detail')
}

// 结转所得税
export const CARRY_OVER_PLAN_INCOME_TAX_VIEW_ROUTE = {
    name: 'carryOverPlanIncomeTaxView',
    path: 'carryOverPlan/incomeTax',
    meta: { name: '结转所得税方案列表', keepAlive: true, uid: 'carryOverPlanIncomeTaxView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/incomeTax')
}

export const CARRY_OVER_PLAN_INCOME_TAX_ADD_VIEW_ROUTE = {
    name: 'carryOverPlanIncomeTaxAddView',
    path: 'carryOverPlan/incomeTax/add',
    meta: { name: '结转所得税方案新增', keepAlive: true, uid: 'carryOverPlanIncomeTaxAddView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/incomeTax/add')
}

export const CARRY_OVER_PLAN_INCOME_TAX_EDIT_VIEW_ROUTE = {
    name: 'carryOverPlanIncomeTaxEditView',
    path: 'carryOverPlan/incomeTax/edit/:id',
    meta: { name: '结转所得税方案编辑', keepAlive: true, uid: 'carryOverPlanIncomeTaxEditView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/incomeTax/edit')
}

export const CARRY_OVER_PLAN_INCOME_TAX_DETAIL_VIEW_ROUTE = {
    name: 'carryOverPlanIncomeTaxDetailView',
    path: 'carryOverPlan/incomeTax/detail/:id',
    meta: { name: '结转所得税方案查看', keepAlive: true, uid: 'carryOverPlanIncomeTaxDetailView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/incomeTax/detail')
}

export const CARRY_OVER_RECORD_INCOME_TAX_VIEW_ROUTE = {
    name: 'carryOverRecordIncomeTaxView',
    path: 'carryOverRecord/incomeTax',
    meta: { name: '结转所得税记录列表', keepAlive: true, uid: 'carryOverRecordIncomeTaxView' },
    component: () =>
        import ('@/views/finance/carryOver/record/incomeTax')
}

export const CARRY_OVER_RECORD_INCOME_TAX_DETAIL_VIEW_ROUTE = {
    name: 'carryOverRecordIncomeTaxDetailView',
    path: 'carryOverRecord/incomeTax/detail/:id',
    meta: { name: '结转所得税记录查看', keepAlive: true, uid: 'carryOverRecordIncomeTaxDetailView' },
    component: () =>
        import ('@/views/finance/carryOver/record/incomeTax/detail')
}

// 结转本年利润
export const CARRY_OVER_PLAN_YEAR_PROFIT_VIEW_ROUTE = {
    name: 'carryOverPlanYearProfitView',
    path: 'carryOverPlan/yearProfit',
    meta: { name: '结转本年利润方案列表', keepAlive: true, uid: 'carryOverPlanYearProfitView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/yearProfit')
}

export const CARRY_OVER_PLAN_YEAR_PROFIT_ADD_VIEW_ROUTE = {
    name: 'carryOverPlanYearProfitAddView',
    path: 'carryOverPlan/yearProfit/add',
    meta: { name: '结转本年利润方案新增', keepAlive: true, uid: 'carryOverPlanYearProfitAddView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/yearProfit/add')
}

export const CARRY_OVER_PLAN_YEAR_PROFIT_EDIT_VIEW_ROUTE = {
    name: 'carryOverPlanYearProfitEditView',
    path: 'carryOverPlan/yearProfit/edit/:id',
    meta: { name: '结转本年利润方案编辑', keepAlive: true, uid: 'carryOverPlanYearProfitEditView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/yearProfit/edit')
}

export const CARRY_OVER_PLAN_YEAR_PROFIT_DETAIL_VIEW_ROUTE = {
    name: 'carryOverPlanYearProfitDetailView',
    path: 'carryOverPlan/yearProfit/detail/:id',
    meta: { name: '结转本年利润方案查看', keepAlive: true, uid: 'carryOverPlanYearProfitDetailView' },
    component: () =>
        import ('@/views/finance/carryOver/plan/yearProfit/detail')
}

export const CARRY_OVER_RECORD_YEAR_PROFIT_VIEW_ROUTE = {
    name: 'carryOverRecordYearProfitView',
    path: 'carryOverRecord/yearProfit',
    meta: { name: '结转本年利润记录列表', keepAlive: true, uid: 'carryOverRecordYearProfitView' },
    component: () =>
        import ('@/views/finance/carryOver/record/yearProfit')
}

export const CARRY_OVER_RECORD_YEAR_PROFIT_DETAIL_VIEW_ROUTE = {
    name: 'carryOverRecordYearProfitDetailView',
    path: 'carryOverRecord/yearProfit/detail/:id',
    meta: { name: '结转本年利润记录查看', keepAlive: true, uid: 'carryOverRecordYearProfitDetailView' },
    component: () =>
        import ('@/views/finance/carryOver/record/yearProfit/detail')
}

// ---------- 计提 ----------------
// 企业所得税弥补亏损明细表
export const EXTRACT_BASE_NUMBER_INCOME_TAX_PROVISION_VIEW_ROUTE = {
    name: 'extractBaseNumberIncomeTaxProvisionView',
    path: 'extractBaseNumber/incomeTaxProvision',
    meta: { name: '企业所得税弥补亏损明细表', keepAlive: true, uid: 'extractBaseNumberIncomeTaxProvisionView' },
    component: () =>
        import ('@/views/finance/extract/baseNumber/incomeTaxProvision')
}
export const EXTRACT_BASE_NUMBER_INCOME_TAX_PROVISION_ADD_VIEW_ROUTE = {
    name: 'extractBaseNumberIncomeTaxProvisionAddView',
    path: 'extractBaseNumber/incomeTaxProvision/add',
    meta: { name: '企业所得税弥补亏损明细表新增', keepAlive: true, uid: 'extractBaseNumberIncomeTaxProvisionAddView' },
    component: () =>
        import ('@/views/finance/extract/baseNumber/incomeTaxProvision/add')
}

export const EXTRACT_BASE_NUMBER_INCOME_TAX_PROVISION_EDIT_VIEW_ROUTE = {
    name: 'extractBaseNumberIncomeTaxProvisionEditView',
    path: 'extractBaseNumber/incomeTaxProvision/edit/:id',
    meta: { name: '企业所得税弥补亏损明细表编辑', keepAlive: true, uid: 'extractBaseNumberIncomeTaxProvisionEditView' },
    component: () =>
        import ('@/views/finance/extract/baseNumber/incomeTaxProvision/edit')
}

export const EXTRACT_BASE_NUMBER_INCOME_TAX_PROVISION_DETAIL_VIEW_ROUTE = {
    name: 'extractBaseNumberIncomeTaxProvisionDetailView',
    path: 'extractBaseNumber/incomeTaxProvision/detail/:id',
    meta: { name: '企业所得税弥补亏损明细表查看', keepAlive: true, uid: 'extractBaseNumberIncomeTaxProvisionDetailView' },
    component: () =>
        import ('@/views/finance/extract/baseNumber/incomeTaxProvision/detail')
}
// 计提企业所得税
export const EXTRACT_PLAN_INCOME_TAX_VIEW_ROUTE = {
    name: 'extractPlanIncomeTaxView',
    path: 'extractPlan/incomeTax',
    meta: { name: '计提企业所得税方案列表', keepAlive: true, uid: 'extractPlanIncomeTaxView' },
    component: () =>
        import ('@/views/finance/extract/plan/incomeTax')
}

export const EXTRACT_PLAN_INCOME_TAX_ADD_VIEW_ROUTE = {
    name: 'extractPlanIncomeTaxAddView',
    path: 'extractPlan/incomeTax/add',
    meta: { name: '计提企业所得税方案新增', keepAlive: true, uid: 'extractPlanIncomeTaxAddView' },
    component: () =>
        import ('@/views/finance/extract/plan/incomeTax/add')
}

export const EXTRACT_PLAN_INCOME_TAX_EDIT_VIEW_ROUTE = {
    name: 'extractPlanIncomeTaxEditView',
    path: 'extractPlan/incomeTax/edit/:id',
    meta: { name: '计提企业所得税方案编辑', keepAlive: true, uid: 'extractPlanIncomeTaxEditView' },
    component: () =>
        import ('@/views/finance/extract/plan/incomeTax/edit')
}

export const EXTRACT_PLAN_INCOME_TAX_DETAIL_VIEW_ROUTE = {
    name: 'extractPlanIncomeTaxDetailView',
    path: 'extractPlan/incomeTax/detail/:id',
    meta: { name: '计提企业所得税方案查看', keepAlive: true, uid: 'extractPlanIncomeTaxDetailView' },
    component: () =>
        import ('@/views/finance/extract/plan/incomeTax/detail')
}

export const EXTRACT_RECORD_INCOME_TAX_VIEW_ROUTE = {
    name: 'extractRecordIncomeTaxView',
    path: 'extractRecord/incomeTax',
    meta: { name: '计提企业所得税记录列表', keepAlive: true, uid: 'extractRecordIncomeTaxView' },
    component: () =>
        import ('@/views/finance/extract/record/incomeTax')
}

export const EXTRACT_RECORD_INCOME_TAX_DETAIL_VIEW_ROUTE = {
    name: 'extractRecordIncomeTaxDetailView',
    path: 'extractRecordI/incomeTax/detail/:id',
    meta: { name: '计提企业所得税记录查看', keepAlive: true, uid: 'extractRecordIncomeTaxDetailView' },
    component: () =>
        import ('@/views/finance/extract/record/incomeTax/detail')
}

// 计提盈余公积
// 计提盈余公积基数
export const EXTRACT_BASE_NUMBER_SURPLUS_VIEW_ROUTE = {
    name: 'extractBaseNumberSurplusView',
    path: 'extractBaseNumber/surplus',
    meta: { name: '盈余提取基数表', keepAlive: true, uid: 'extractBaseNumberSurplusView' },
    component: () =>
        import ('@/views/finance/extract/baseNumber/surplus')
}
// 计提盈余方案
export const EXTRACT_PLAN_SURPLUS_VIEW_ROUTE = {
    name: 'extractPlanSurplusView',
    path: 'extractPlan/surplus',
    meta: { name: '盈余提取方案列表', keepAlive: true, uid: 'extractPlanSurplusView' },
    component: () =>
        import ('@/views/finance/extract/plan/surplus')
}

export const EXTRACT_PLAN_SURPLUS_ADD_VIEW_ROUTE = {
    name: 'extractPlanSurplusAddView',
    path: 'extractPlan/surplus/add',
    meta: { name: '盈余提取方案新增', keepAlive: true, uid: 'extractPlanSurplusAddView' },
    component: () =>
        import ('@/views/finance/extract/plan/surplus/add')
}

export const EXTRACT_PLAN_SURPLUS_EDIT_VIEW_ROUTE = {
    name: 'extractPlanSurplusEditView',
    path: 'extractPlan/surplus/edit/:id',
    meta: { name: '盈余提取方案编辑', keepAlive: true, uid: 'extractPlanSurplusEditView' },
    component: () =>
        import ('@/views/finance/extract/plan/surplus/edit')
}

export const EXTRACT_PLAN_SURPLUS_DETAIL_VIEW_ROUTE = {
    name: 'extractPlanSurplusDetailView',
    path: 'extractPlan/surplus/detail/:id',
    meta: { name: '盈余提取方案查看', keepAlive: true, uid: 'extractPlanSurplusDetailView' },
    component: () =>
        import ('@/views/finance/extract/plan/surplus/detail')
}

export const EXTRACT_RECORD_SURPLUS_VIEW_ROUTE = {
    name: 'extractRecordSurplusView',
    path: 'extractRecordSurplus/surplus',
    meta: { name: '盈余提取记录列表', keepAlive: true, uid: 'extractRecordSurplusView' },
    component: () =>
        import ('@/views/finance/extract/record/surplus')
}

export const EXTRACT_RECORD_SURPLUS_DETAIL_VIEW_ROUTE = {
    name: 'extractRecordSurplusDetailView',
    path: 'extractRecordSurplus/surplus/detail/:id',
    meta: { name: '盈余提取记录查看', keepAlive: true, uid: 'extractRecordSurplusDetailView' },
    component: () =>
        import ('@/views/finance/extract/record/surplus/detail')
}

// 结账
export const SETTLE_RECORD_VIEW_ROUTE = {
    name: 'settleRecordView',
    path: 'settle/record',
    meta: { name: '结账', keepAlive: true, uid: 'settleRecordView' },
    component: () =>
        import ('@/views/finance/settle/record')
}

// 科目期初
export const INIT_SUBJECT_VIEW_ROUTE = {
    name: 'initSubjectView',
    path: 'init/subject',
    meta: { name: '科目期初', keepAlive: true, uid: 'initSubjectView' },
    component: () =>
        import ('@/views/finance/init/subject')
}

export const INIT_CASH_FLOW_VIEW_ROUTE = {
    name: 'initCashFlowView',
    path: 'initCashFlow',
    meta: { name: '现金流量期初', keepAlive: true, uid: 'initCashFlowView' },
    component: () =>
        import ('@/views/finance/init/cashFlow')
}

// 账簿初始化
export const INIT_ACCOUNTING_BOOK_VIEW_ROUTE = {
    name: 'initAccountingBookView',
    path: 'init/accountingBook',
    meta: { name: '账簿初始化', keepAlive: true, uid: 'initAccountingBookView' },
    component: () =>
        import ('@/views/finance/init/accountingBook')
}

export const INIT_ACCOUNTING_BOOK_DETAIL_VIEW_ROUTE = {
    name: 'initAccountingBookDetailView',
    path: 'init/accountingBook/detail/:id',
    meta: { name: '账簿初始化查看', keepAlive: true, uid: 'initAccountingBookDetailView' },
    component: () =>
        import ('@/views/finance/init/accountingBook/detail')
}

// 现金流量项目分类 cashFlowCategory CASH_FLOW_CATEGORY
export const CASH_FLOW_CATEGORY_VIEW_ROUTE = {
    name: 'cashFlowCategoryView',
    path: 'cashFlow/category',
    meta: { name: '现金流量项目分类', keepAlive: true, uid: 'cashFlowCategoryView' },
    component: () =>
        import ('@/views/finance/cashFlow/category')
}

export const CASH_FLOW_CATEGORY_ADD_VIEW_ROUTE = {
    name: 'cashFlowCategoryAddView',
    path: 'cashFlow/category/add',
    meta: { name: '现金流量项目分类新增', keepAlive: true, uid: 'cashFlowCategoryAddView' },
    component: () =>
        import ('@/views/finance/cashFlow/category/add')
}

export const CASH_FLOW_CATEGORY_EDIT_VIEW_ROUTE = {
    name: 'cashFlowCategoryEditView',
    path: 'cashFlow/category/edit/:id',
    meta: { name: '现金流量项目分类编辑', keepAlive: true, uid: 'cashFlowCategoryEditView' },
    component: () =>
        import ('@/views/finance/cashFlow/category/edit')
}

export const CASH_FLOW_CATEGORY_DETAIL_VIEW_ROUTE = {
    name: 'cashFlowCategoryDetailView',
    path: 'cashFlow/category/detail/:id',
    meta: { name: '现金流量项目分类查看', keepAlive: true, uid: 'cashFlowCategoryDetailView' },
    component: () =>
        import ('@/views/finance/cashFlow/category/detail')
}

// 现金流量项目 cashFlowProject CASH_FLOW_PROJECT
export const CASH_FLOW_PROJECT_VIEW_ROUTE = {
    name: 'cashFlowProjectView',
    path: 'cashFlow/project',
    meta: { name: '现金流量项目', keepAlive: true, uid: 'cashFlowProjectView' },
    component: () =>
        import ('@/views/finance/cashFlow/project')
}

export const CASH_FLOW_PROJECT_ADD_VIEW_ROUTE = {
    name: 'cashFlowProjectAddView',
    path: 'cashFlow/project/add',
    meta: { name: '现金流量项目新增', keepAlive: true, uid: 'cashFlowProjectAddView' },
    component: () =>
        import ('@/views/finance/cashFlow/project/add')
}

export const CASH_FLOW_PROJECT_EDIT_VIEW_ROUTE = {
    name: 'cashFlowProjectEditView',
    path: 'cashFlow/project/edit/:id',
    meta: { name: '现金流量项目编辑', keepAlive: true, uid: 'cashFlowProjectEditView' },
    component: () =>
        import ('@/views/finance/cashFlow/project/edit')
}

export const CASH_FLOW_PROJECT_DETAIL_VIEW_ROUTE = {
    name: 'cashFlowProjectDetailView',
    path: 'cashFlow/project/detail/:id',
    meta: { name: '现金流量项目查看', keepAlive: true, uid: 'cashFlowProjectDetailView' },
    component: () =>
        import ('@/views/finance/cashFlow/project/detail')
}

// 现金流量项目 - 定义 cashFlowDefinition CASH_FLOW_DEFINITION
export const CASH_FLOW_DEFINITION_VIEW_ROUTE = {
    name: 'cashFlowDefinitionView',
    path: 'cashFlow/definition',
    meta: { name: '现金流量项目定义', keepAlive: true, uid: 'cashFlowDefinitionView' },
    component: () =>
        import ('@/views/finance/cashFlow/definition')
}

// 现金流量列表 cashFlow CASH_FLOW
export const CASH_FLOW_VIEW_ROUTE = {
    name: 'cashFlowView',
    path: 'cashFlow',
    meta: { name: '现金流量列表', keepAlive: true, uid: 'cashFlowView' },
    component: () =>
        import ('@/views/finance/cashFlow')
}

export const CASH_FLOW_EDIT_VIEW_ROUTE = {
    name: 'cashFlowEditView',
    path: 'cashFlow/edit/:id',
    meta: { name: '现金流量分配编辑', keepAlive: true, uid: 'cashFlowEditView' },
    component: () =>
        import ('@/views/finance/cashFlow/edit')
}

export const CASH_FLOW_DETAIL_VIEW_ROUTE = {
    name: 'cashFlowDetailView',
    path: 'cashFlow/detail/:id',
    meta: { name: '现金流量分配详情', keepAlive: true, uid: 'cashFlowDetailView' },
    component: () =>
        import ('@/views/finance/cashFlow/detail')
}

// 现金流量明细 cashFlowItem CASH_FLOW_ITEM
export const CASH_FLOW_ITEM_VIEW_ROUTE = {
    name: 'cashFlowItemView',
    path: 'cashFlow/item',
    meta: { name: '现金流量明细表', keepAlive: true, uid: 'cashFlowItemView' },
    component: () =>
        import ('@/views/finance/cashFlow/item')
}

// 现金流量汇总 cashFlowItemReport CASH_FLOW_ITEM_REPORT
export const CASH_FLOW_ITEM_REPORT_VIEW_ROUTE = {
    name: 'cashFlowItemReportView',
    path: 'cashFlow/item/report',
    meta: { name: '现金流量汇总表', keepAlive: true, uid: 'cashFlowItemReportView' },
    component: () =>
        import ('@/views/finance/cashFlow/item/report')
}

// 财务报表
// 1.资产负债表
export const REPORT_BALANCE_SHEET_VIEW_ROUTE = {
    name: 'reportBalanceSheetView',
    path: 'report/balanceSheet',
    meta: { name: '资产负债表', keepAlive: true, uid: 'reportBalanceSheetView' },
    component: () =>
        import ('@/views/finance/report/balanceSheet')
}

export const REPORT_BALANCE_SHEET_FORMULA_VIEW_ROUTE = {
    name: 'reportBalanceSheetFormulaView',
    path: 'report/balanceSheet/formula',
    meta: { name: '资产负债表公式', keepAlive: true, uid: 'reportBalanceSheetFormulaView' },
    component: () =>
        import ('@/views/finance/report/balanceSheet/formula')
}

// 2.利润表
export const REPORT_PROFITE_VIEW_ROUTE = {
    name: 'reportProfitView',
    path: 'report/profit',
    meta: { name: '利润表', keepAlive: true, uid: 'reportProfitView' },
    component: () =>
        import ('@/views/finance/report/profit')
}

export const REPORT_PROFITE_FORMULA_VIEW_ROUTE = {
    name: 'reportProfitFormulaView',
    path: 'report/profit/formula',
    meta: { name: '利润表公式', keepAlive: true, uid: 'reportProfitFormulaView' },
    component: () =>
        import ('@/views/finance/report/profit/formula')
}

// 3. 现金流量简表
export const REPORT_CASH_FLOW_VIEW_ROUTE = {
    name: 'reportCashFlowView',
    path: 'report/cashFlow',
    meta: { name: '现金流量简表', keepAlive: true, uid: 'reportCashFlowView' },
    component: () =>
        import ('@/views/finance/report/cashFlow')
}

export const REPORT_CASH_FLOW_FORMULA_VIEW_ROUTE = {
    name: 'reportCashFlowFormulaView',
    path: 'report/cashFlow/formula',
    meta: { name: '现金流量简表公式', keepAlive: true, uid: 'reportCashFlowFormulaView' },
    component: () =>
        import ('@/views/finance/report/cashFlow/formula')
}

// 4. 所有者权益变动表
export const REPORT_EQUITY_CHANGE_VIEW_ROUTE = {
    name: 'reportEquityChangeView',
    path: 'report/equityChange',
    meta: { name: '所有者权益变动表', keepAlive: true, uid: 'reportEquityChangeView' },
    component: () =>
        import ('@/views/finance/report/equityChange')
}

// 自定义报表
// 自定义报表 - 分类
export const REPORT_CUSTOM_CATEGORY_VIEW_ROUTE = {
    name: 'reportCustomCategoryView',
    path: 'report/custom/category',
    meta: { name: '自定义报表分类', keepAlive: true, uid: 'reportCustomCategoryView' },
    component: () =>
        import ('@/views/finance/report/custom/category')
}

export const REPORT_CUSTOM_CATEGORY_ADD_VIEW_ROUTE = {
    name: 'reportCustomCategoryAddView',
    path: 'report/custom/category/add',
    meta: { name: '自定义报表分类新增', keepAlive: true, uid: 'reportCustomCategoryAddView' },
    component: () =>
        import ('@/views/finance/report/custom/category/add')
}

export const REPORT_CUSTOM_CATEGORY_EDIT_VIEW_ROUTE = {
    name: 'reportCustomCategoryEditView',
    path: 'report/custom/category/edit/:id',
    meta: { name: '自定义报表分类编辑', keepAlive: true, uid: 'reportCustomCategoryEditView' },
    component: () =>
        import ('@/views/finance/report/custom/category/edit')
}

export const REPORT_CUSTOM_CATEGORY_DETAIL_VIEW_ROUTE = {
    name: 'reportCustomCategoryDetailView',
    path: 'report/custom/category/detail/:id',
    meta: { name: '自定义报表分类查看', keepAlive: true, uid: 'reportCustomCategoryDetailView' },
    component: () =>
        import ('@/views/finance/report/custom/category/detail')
}

// 自定义报表
export const REPORT_CUSTOM_VIEW_ROUTE = {
    name: 'reportCustomView',
    path: 'report/custom',
    meta: { name: '自定义报表', keepAlive: true, uid: 'reportCustomView' },
    component: () =>
        import ('@/views/finance/report/custom')
}

export const REPORT_CUSTOM_ADD_VIEW_ROUTE = {
    name: 'reportCustomAddView',
    path: 'report/custom/add',
    meta: { name: '自定义报表新增', keepAlive: true, uid: 'reportCustomAddView' },
    component: () =>
        import ('@/views/finance/report/custom/add')
}

export const REPORT_CUSTOM_EDIT_VIEW_ROUTE = {
    name: 'reportCustomEditView',
    path: 'report/custom/edit/:id',
    meta: { name: '自定义报表编辑', keepAlive: true, uid: 'reportCustomEditView' },
    component: () =>
        import ('@/views/finance/report/custom/edit')
}

export const REPORT_CUSTOM_DETAIL_VIEW_ROUTE = {
    name: 'reportCustomDetailView',
    path: 'report/custom/detail/:id',
    meta: { name: '自定义报表查看', keepAlive: true, uid: 'reportCustomDetailView' },
    component: () =>
        import ('@/views/finance/report/custom/detail')
}

// 分析
// 资产负债表
// 资产负债表要素趋势分析
export const REPORT_ANALYSIS_BALANCE_SHEET_TREND_VIEW_ROUTE = {
    name: 'reportAnalysisBalanceSheetTrendView',
    path: 'report/analysis/balanceSheet/trend',
    meta: { name: '资产负债表要素趋势分析', keepAlive: true, uid: 'reportAnalysisBalanceSheetTrendView' },
    component: () =>
        import ('@/views/finance/report/analysis/balanceSheet/trend')
}
// 资产负债表要素同比分析
export const REPORT_ANALYSIS_BALANCE_SHEET_YEAR_ON_YEAR_VIEW_ROUTE = {
    name: 'reportAnalysisBalanceSheetYearOnYearView',
    path: 'report/analysis/balanceSheet/yearOnYear',
    meta: { name: '资产负债表要素同比分析', keepAlive: true, uid: 'reportAnalysisBalanceSheetYearOnYearView' },
    component: () =>
        import ('@/views/finance/report/analysis/balanceSheet/yearOnYear')
}
// 资产负债表项分析
export const REPORT_ANALYSIS_BALANCE_SHEET_ITEM_VIEW_ROUTE = {
    name: 'reportAnalysisBalanceSheetItemView',
    path: 'report/analysis/balanceSheet/item',
    meta: { name: '资产负债表表项分析', keepAlive: true, uid: 'reportAnalysisBalanceSheetItemView' },
    component: () =>
        import ('@/views/finance/report/analysis/balanceSheet/item')
}

// 利润表表项分析
export const REPORT_ANALYSIS_PROFIT_TREND_VIEW_ROUTE = {
    name: 'reportAnalysisProfitTrendView',
    path: 'report/analysis/profit/trend',
    meta: { name: '利润表要素趋势分析', keepAlive: true, uid: 'reportAnalysisProfitTrendView' },
    component: () =>
        import ('@/views/finance/report/analysis/profit/trend')
}
// 利润表要素同比分析
export const REPORT_ANALYSIS_PROFIT_YEAR_ON_YEAR_VIEW_ROUTE = {
    name: 'reportAnalysisProfitYearOnYearView',
    path: 'report/analysis/profit/yearOnYear',
    meta: { name: '利润表要素同比分析', keepAlive: true, uid: 'reportAnalysisProfitYearOnYearView' },
    component: () =>
        import ('@/views/finance/report/analysis/profit/yearOnYear')
}
// 利润表表项趋势分析
export const REPORT_ANALYSIS_PROFIT_ITEM_TREND_VIEW_ROUTE = {
    name: 'reportAnalysisProfitItemTrendView',
    path: 'report/analysis/profit/itemTrend',
    meta: { name: '利润表表项趋势分析', keepAlive: true, uid: 'reportAnalysisProfitItemTrendView' },
    component: () =>
        import ('@/views/finance/report/analysis/profit/itemTrend')
}
// 利润表表项同比分析
export const REPORT_ANALYSIS_PROFIT_ITEM_YEAR_ON_YEAR_VIEW_ROUTE = {
    name: 'reportAnalysisProfitItemYearOnYearView',
    path: 'report/analysis/profit/itemYearOnYear',
    meta: { name: '利润表表项同比分析', keepAlive: true, uid: 'reportAnalysisProfitItemYearOnYearView' },
    component: () =>
        import ('@/views/finance/report/analysis/profit/itemYearOnYear')
}
// 利润表表项分析
export const REPORT_ANALYSIS_PROFIT_ITEM_VIEW_ROUTE = {
    name: 'reportAnalysisProfitItemView',
    path: 'report/analysis/profit/item',
    meta: { name: '利润表表项分析', keepAlive: true, uid: 'reportAnalysisProfitItemView' },
    component: () =>
        import ('@/views/finance/report/analysis/profit/item')
}
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/app.scss'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// import locale from 'element-plus/lib/locale/lang/zh-cn'
// import echarts from 'echarts'

// svg
import '@/components/svg/index.js'
import SvgIcon from '@/components/svg/SvgIcon.vue'

// axios
// import request from '@/api/request'

const app = createApp(App)
app.use(router)
app.use(store)

app.component('svg-icon', SvgIcon) // 注册为全局组件
// app.config.globalProperties.$request = request // 挂载为全局属性
// app.config.globalProperties.$router = router
// app.config.globalProperties.$echarts = echarts

app.mount('#app')
// 应付单
export const AP_VIEW_ROUTE = {
    name: 'apView',
    path: 'arap/ap',
    meta: { name: '应付单', keepAlive: true, uid: 'apView' },
    component: () =>
        import ('@/views/arap/ap')
}

export const AP_DETAIL_VIEW_ROUTE = {
    name: 'apDetailView',
    path: 'arap/ap/detail/:id',
    meta: { name: '应付单查看', keepAlive: true, uid: 'apDetailView' },
    component: () =>
        import ('@/views/arap/ap/detail')
}

// 应收单
export const AR_VIEW_ROUTE = {
    name: 'arView',
    path: 'arap/ar',
    meta: { name: '应收单', keepAlive: true, uid: 'arView' },
    component: () =>
        import ('@/views/arap/ar')
}

export const AR_DETAIL_VIEW_ROUTE = {
    name: 'arDetailView',
    path: 'arap/ar/detail/:id',
    meta: { name: '应收单查看', keepAlive: true, uid: 'arDetailView' },
    component: () =>
        import ('@/views/arap/ar/detail')
}

// 应付执行单
export const AP_CONFIRM_VIEW_ROUTE = {
    name: 'apConfirmView',
    path: 'arap/ap/confirm',
    meta: { name: '应付执行单', keepAlive: true, uid: 'apConfirmView' },
    component: () =>
        import ('@/views/arap/ap/confirm')
}

export const AP_CONFIRM_ADD_VIEW_ROUTE = {
    name: 'apConfirmAddView',
    path: 'arap/ap/confirm/add',
    meta: { name: '应付执行单新增', keepAlive: true, uid: 'apConfirmAddView' },
    component: () =>
        import ('@/views/arap/ap/confirm/add')
}

export const AP_CONFIRM_DETAIL_VIEW_ROUTE = {
    name: 'apConfirmDetailView',
    path: 'arap/ap/confirm/detail/:id',
    meta: { name: '应付单执行单查看', keepAlive: true, uid: 'apConfirmDetailView' },
    component: () =>
        import ('@/views/arap/ap/confirm/detail')
}

export const AP_CONFIRM_EDIT_VIEW_ROUTE = {
    name: 'apConfirmEditView',
    path: 'arap/ap/confirm/edit/:id',
    meta: { name: '应付执行单编辑', keepAlive: true, uid: 'apConfirmEditView' },
    component: () =>
        import ('@/views/arap/ap/confirm/edit')
}

// 应收执行单
export const AR_CONFIRM_VIEW_ROUTE = {
    name: 'arConfirmView',
    path: 'arap/ar/confirm',
    meta: { name: '应收执行单', keepAlive: true, uid: 'arConfirmView' },
    component: () =>
        import ('@/views/arap/ar/confirm')
}

export const AR_CONFIRM_ADD_VIEW_ROUTE = {
    name: 'arConfirmAddView',
    path: 'arap/ar/confirm/add',
    meta: { name: '应收执行单新增', keepAlive: true, uid: 'arConfirmAddView' },
    component: () =>
        import ('@/views/arap/ar/confirm/add')
}

export const AR_CONFIRM_DETAIL_VIEW_ROUTE = {
    name: 'arConfirmDetailView',
    path: 'arap/ar/confirm/detail/:id',
    meta: { name: '应收单执行单查看', keepAlive: true, uid: 'arConfirmDetailView' },
    component: () =>
        import ('@/views/arap/ar/confirm/detail')
}

export const AR_CONFIRM_EDIT_VIEW_ROUTE = {
    name: 'arConfirmEditView',
    path: 'arap/ar/confirm/edit/:id',
    meta: { name: '应收执行单编辑', keepAlive: true, uid: 'arConfirmEditView' },
    component: () =>
        import ('@/views/arap/ar/confirm/edit')
}
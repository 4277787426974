// 发票
export const INVOICE_VIEW_ROUTE = {
    name: 'invoiceView',
    path: 'tax/invoice',
    meta: { name: '发票列表', keepAlive: true, uid: 'invoiceView' },
    component: () =>
        import ('@/views/tax/invoice')
}

export const INVOICE_DETAIL_VIEW_ROUTE = {
    name: 'invoiceDetailView',
    path: 'tax/invoice/detail/:id',
    meta: { name: '发票查看', keepAlive: true, uid: 'invoiceDetailView' },
    component: () =>
        import ('@/views/tax/invoice/detail')
}

// 发票项目
export const INVOICE_PROJECT_VIEW_ROUTE = {
    name: 'invoiceProjectView',
    path: 'tax/invoice/project',
    meta: { name: '发票项目列表', keepAlive: true, uid: 'invoiceProjectView' },
    component: () =>
        import ('@/views/tax/invoice/project')
}

export const INVOICE_PROJECT_ADD_VIEW_ROUTE = {
    name: 'invoiceProjectAddView',
    path: 'tax/invoice/project/add',
    meta: { name: '发票项目新增', keepAlive: true, uid: 'invoiceProjectAddView' },
    component: () =>
        import ('@/views/tax/invoice/project/add')
}

export const INVOICE_PROJECT_DETAIL_VIEW_ROUTE = {
    name: 'invoiceProjectDetailView',
    path: 'tax/invoice/project/detail/:id',
    meta: { name: '发票项目查看', keepAlive: true, uid: 'invoiceProjectDetailView' },
    component: () =>
        import ('@/views/tax/invoice/project/detail')
}

export const INVOICE_PROJECT_EDIT_VIEW_ROUTE = {
    name: 'invoiceProjectEditView',
    path: 'tax/invoice/project/edit/:id',
    meta: { name: '发票项目编辑', keepAlive: true, uid: 'invoiceProjectEditView' },
    component: () =>
        import ('@/views/tax/invoice/project/edit')
}
export default {
    state: () => {
        return {
            userInfo: {}
        }
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        getUserInfo(state) {
            return state.userInfo
        }
    },
    actions: {
        setUserInfo(context, userInfo) {
            context.commit('setUserInfo', userInfo)
        },
        getUserInfo(context) {
            return context.commit('getUserInfo')
        }
    }
}
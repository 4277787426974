import { createStore } from 'vuex'
import layoutModule from './layout'
import userModule from './user'
const store = createStore({
    modules: {
        layout: layoutModule,
        user: userModule
    }
})

export default store